import { useEffect } from "react";
import "./Typeform.css"

export default function TypeformEmbed() {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://embed.typeform.com/next/embed.js";
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div className="typeForm" data-tf-live="01JNQ6WG6ZK05NH0KX4XMMWMM2"></div>
  );
}
