import { useState } from "react";
import { ModalContact } from "./Components/ModalContact/ModalContact";
import { Sidebar } from "./Shared/Sidebar/Sidebar";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import CookieBar from "./Components/Cookie/CookieBar";

import "./App.css";

import Header from "./Shared/Header/Header";
import ContactSection from "./Components/ContactSection/ContactSection";
import Footer from "./Shared/Footer/Footer";
import Home from "./Components/Home/Home";
import Sirenes from "./Pages/Products/Sirenes";
import Audiovisuais from "./Pages/Products/Audiovisuais";
import Privacy from "./Pages/Privacy/Privacy";
import AboutUs from "./Pages/About-us/About-us";
import LpBTKSRZ3900A from "./LandingPages/BTK-SRZ3900/Lp-BTK-SRZ3900-A/Lp-BTK-SRZ3900-A";
import TypeformEmbed from "./Pages/Typeform/Typeform";

function App() {
  const [showModal, setShowModal] = useState(false);
  const [showMenu, setShowMenu] = useState(false);
  const [sidebarWidth, setsidebarWidth] = useState("sidebar colapse");

  const openModal = () => setShowModal((prev) => !prev);
  const openMenu = () => {
    setShowMenu((prev) => !prev);
    showMenu ? setsidebarWidth("sidebar colapse") : setsidebarWidth("sidebar");
  };

  return (
    <Router>
      <body className="App">
        <Sidebar sidebarWidth={sidebarWidth} />
        <Header openMenu={openMenu} openModal={openModal} />
        <main className="AppBody">
          <Routes>
            <Route path="/" element={<Home openModal={openModal} />} />
            <Route path="/sirenes" element={<Sirenes />} />
            <Route
              path="/sinalizadores-audiovisuais"
              element={<Audiovisuais />}
            />
            <Route path="/pesquisa" element={<TypeformEmbed />} />
            <Route path="/politica-de-privacidade" element={<Privacy />} />
            <Route path="/sobre-nos" element={<AboutUs />} />
            <Route path="/BTK-SRZ3900" element={<LpBTKSRZ3900A />} />
          </Routes>
          <ContactSection />
        </main>
        <Footer />
        <ModalContact showModal={showModal} setShowModal={setShowModal} />
      </body>
      <CookieBar />
    </Router>
  );
}

export default App;
